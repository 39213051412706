import React from "react";
import { useTranslation } from "react-i18next";

import CommanHeader from "components/Header";
import Footer from "components/Footer";
import Feature from "components/Feature";

const Whiteboard = () => {
  const { t } = useTranslation();
  const whiteboardData = t("WhiteboardPage");
  return (
    <>
      <CommanHeader />
      <div className="container">
        <div className="row row-grid justify-content-around align-items-center py-8">
          {whiteboardData?.map((wbinfo, key) => (
            <Feature info={wbinfo} key={key} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Whiteboard;
