import React from "react";
import Question from "./Question";
import { useTranslation } from "react-i18next";
import * as Icon from "react-feather";

const FAQ = () => {
  const { t } = useTranslation();

  const questions = t("FAQ").qna;
  return (
    <section className="slice slice-lg bg-section-secondary f16 pt-0">
      <div className="container">
        <h2 className="text-center mb-2 pb-2 fw-bold">FAQ</h2>
        <p className="text-center mb-5 lead lh-180 text-muted">
          {t("FAQ").label}
        </p>

        <div className="row">
          {questions &&
            questions.map((que, key) => {
              return <Question que={que} key={"que-" + key} />;
            })}
        </div>
        <div className="text-center text-md-left mt-4">
          <a
            className="btn btn-primary btn-icon mr-2"
            data-toggle="modal"
            data-target="#contactusModal"
          >
            <span className="btn-inner--text text-capitalize">
              {t("landing").price[5]}
            </span>
            <span className="btn-inner--icon">
              {" "}
              <Icon.ArrowRight size="18" />{" "}
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
