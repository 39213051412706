import { useContext, useEffect, useState } from "react";

import {
  LeaveIcon,
  TeamsMonochromeIcon,
} from "@fluentui/react-icons-northstar";
import { Button, Flex, Image, Segment } from "@fluentui/react-northstar";
import image from "assets/img/icon.png";
import { loadGapiInsideDOM } from "gapi-script";
import GoogleLogin from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import "./Auth.css";

import config from "config";
import { UserContext } from "context/UserContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Auth = (props) => {
  const { login, logout } = props;
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { insideTeam, nonSubscriber, user } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      await loadGapiInsideDOM();
    })();
  });

  useEffect(() => {
    if (state?.from) {
      // For redirect on credit page after successfully login
      localStorage.setItem("from", state?.from);
    }
  }, [state]);

  const onFailure = (err) => {
    console.log("GOOGLE LOGIN ERROR", err);
    const from = localStorage.getItem("from");
    from && localStorage.removeItem("from");
  };

  const onSuccess = (res) => {
    console.log("onSuccess:called");
    const from = localStorage.getItem("from");
    console.log("from", from);
    localStorage.removeItem("IsUserCreateAccount");
    login("google", res);
    if (from) {
      localStorage.removeItem("from");
      history.push(from);
    } else {
      history.push("/profile");
    }
  };

  useEffect(() => {
    const from = localStorage.getItem("from");
    // state : values passed from redirect
    if (user && from) {
      history.push(from);
    } else {
      if (user && !nonSubscriber) {
        history.push("/profile");
      }
    }
  }, [nonSubscriber, user]);

  return (
    <div className="Auth Karla">
      <Flex gap="gap.small" padding="padding.medium" className="flexBox">
        <Flex.Item hAlign="center" vAlign="center">
          <Segment
            content={
              <>
                <Link to="/">
                  <Image src={image} style={{ borderRadius: "4px" }} />
                </Link>
                <br />
                <br />
                <h1 style={{ color: "black" }}>
                  {nonSubscriber
                    ? "Subscribe to ACV"
                    : "Authenticate your self"}
                </h1>

                {nonSubscriber ? (
                  <p>
                    Sorry, we can't find an account with this email address.
                    Please try again or create a new account.
                  </p>
                ) : (
                  <p>
                    It seems like you are not signed in. <br /> If you have an
                    account you can sign in or create one using sign up.
                  </p>
                )}

                <br />
                {!nonSubscriber && (
                  <Button
                    icon={
                      <TeamsMonochromeIcon
                        size="larger"
                        style={{ paddingRight: "3px" }}
                      />
                    }
                    content={
                      insideTeam
                        ? "Sign in with Teams"
                        : "Sign in with Microsoft"
                    }
                    iconPosition="before"
                    primary
                    onClick={() => {
                      localStorage.removeItem("IsUserCreateAccount");
                      login("microsoft");
                    }}
                  />
                )}
                {!nonSubscriber && (
                  <GoogleLogin
                    clientId={config.GOOGLE_CLIENT_ID}
                    buttonText="Sign in with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    className="auth_google_btn mt-2 mt-lg-0 ml-md-3"
                    scope="profile"
                    redirectUri={config.APP_URI}
                  />
                )}
                {nonSubscriber && (
                  <Button
                    icon={<LeaveIcon />}
                    content={`Sign out from ${user?.username}`}
                    iconPosition="before"
                    primary
                    onClick={() => logout()}
                  />
                )}
                <Button
                  content={
                    nonSubscriber ? "How to Subscribe ACV" : "Sign up with ACV"
                  }
                  onClick={() => window.open(`${config.DOC_URL}/#acv-account`)}
                  className={nonSubscriber ? "btn" : "btn d-none d-lg-inline"}
                />
              </>
            }
            style={{ textAlign: "center" }}
          />
        </Flex.Item>
      </Flex>
    </div>
  );
};
export default Auth;
