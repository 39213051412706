import axios from "axios";
import "./UnsubscribeNotification.css";
import IconLogo from "assets/img/icon.png";
import config from "config";
import { useEffect, useState } from "react";

const UnsubscribeNotification = () => {
  const params = new URLSearchParams(document.location.search);
  const mailFromParams = params.get("mail");

  const [openAlert, setOpenAlert] = useState(false);
  const [mail, setMail] = useState(mailFromParams);
  const [loader, setLoader] = useState(false);

  const handleUnSubscribe = async () => {
    if (mail) {
      try {
        setLoader(true);
        const response = await axios.post(
          `${config.ENDPOINT}/api/user/unsubscribe-notification`,
          {
            mail: mail,
          }
        );
        setLoader(false);
        if (response.data.status === "Success") {
          setOpenAlert(true);
        } else if (response.data.status === "Error") {
          setOpenAlert(true);
        } else {
          setOpenAlert(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error("Please enter your email address");
      window.alert("Please enter your email address");
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setOpenAlert(false);
    }, 3000);
  }, [openAlert]);

  return (
    <>
      {openAlert && (
        <div
          className={
            openAlert
              ? "alert alert-success fixed-bottom alert-dismissible fade show"
              : "alert alert-danger fixed-bottom alert-dismissible fade show"
          }
          role="alert"
          style={{
            zIndex: "9999",
            position: "fixed",
            left: "auto",
            top: "20px",
            bottom: "auto",
            right: "20px",
          }}
        >
          {openAlert
            ? "You have been unsubscribed successfully"
            : "Your request is rejected! Please try again."}
          <button
            type="button"
            className="close"
            onClick={() =>
              document
                .getElementsByClassName("alert")[0]
                ?.classList.remove("show")
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <div className="unsubscribe-wrapper">
        <div className="unsubscribe-header">
          <div className="unsubscribe-header-logo">
            <div className="title">
              <img
                alt="ACV - Tangible IT"
                src={IconLogo}
                className="acv-logo"
                id="navbar-logo"
              />
              <h1>Please Update your email preferences</h1>
              <h6 style={{ marginBottom: "0px" }}>
                Please enter you email address if you'd like to unsubscribe from
                future ACV communications.
              </h6>
            </div>
            <div className="unsubscribe-form">
              <input
                type="email"
                placeholder="Enter your email"
                className="unsubscribe-input"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
              <button
                className="button"
                onClick={handleUnSubscribe}
                disabled={loader}
              >
                {loader && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                  ></span>
                )}
                Unsubscribe
              </button>
            </div>
          </div>
        </div>
        <div className="unsubscribe-footer">
          <p>Are you sure you want to unsubscribe?</p>
          <p>
            By unsubscribing, you will no longer receive any marketing emails
            from ACV.
          </p>
        </div>
      </div>
    </>
  );
};

export default UnsubscribeNotification;
