import React from "react";
import { useTranslation } from "react-i18next";
import CommanHeader from "components/Header";
import Footer from "components/Footer";

import parse from "html-react-parser";

const Privacy = (props, { insideTeam }) => {
  const { t } = useTranslation();
  return (
    <div className="Landing Privacy font-1">
      <CommanHeader />
      {parse(t("privacy"))}
      <Footer />
    </div>
  );
};

export default Privacy;
