import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CommanHeader from "components/Header";
import FeatureCardIn404 from "components/FeatureCardIn404";
import Footer from "components/Footer";
import config from "config";
import "./PageNotFound.css";

const PageNotFound = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <CommanHeader />
      <div className="page_not_found container">
        <div className="svg_container container">
          <img
            src={`https://${config.STORAGE}.blob.core.windows.net/react-images/404-error.svg`}
          />
        </div>
        <div className="page_content_wrapper">
          <h1> {t("page_not_found")[0]}</h1>
          <p className="">{t("page_not_found")[1]}</p>
          <p> {t("page_not_found")[2]}</p>
          <Link to="/">
            <button className="mb-5 btn btn-primary">
              {" "}
              {t("page_not_found")[3]}
            </button>
          </Link>
          <div className="feature_container">
            <FeatureCardIn404 />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;
