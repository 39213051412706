import React, { useContext } from "react";
import {
  Dialog,
  Input,
  TextArea,
  Form,
  FormButton,
  Button,
  Flex,
} from "@fluentui/react-northstar";
import axios from "axios";
import config from "config";

import "./School.css";
import { UserContext } from "context/UserContext";
import logo from "assets/img/MySchool-logo.png";

const School = () => {
  const { user, accessToken, nonSubscriber } = useContext(UserContext);

  const url = config.ENDPOINT;
  const [state, setState] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [schoolData, setSchoolData] = React.useState(null);
  const [accDlg, setAccDlg] = React.useState(false);
  const [dataSaved, setDataSaved] = React.useState(false);
  const [onUpload, setOnUpload] = React.useState(false);
  const [contentEditable, setContentEditable] = React.useState(false);

  React.useEffect(() => {
    if (user && user.slug && !nonSubscriber) {
      axios
        .get(`${url}/api/school/get-school-info${user?.slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((res) => {
          setSchoolData(res.data?.result[0] || null);
          setLoad(false);
          setState({
            SchoolLogo: res.data?.result[0].SchoolLogo,
            NewSchoolLogo: null,
            SchoolName: res.data?.result[0].School,
            SchoolEmail: res.data?.result[0].ContactEmail,
            SchoolAddress: res.data?.result[0].SchoolAddress,
            SchoolPhone: res.data?.result[0].ContactPhone,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.status === 401) {
            setAccDlg(true);
            setLoad(false);
          }
        });
    }
  }, [user, accessToken, nonSubscriber]);

  return (
    <div className="School container">
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">
            No big deal — but we need to refresh to show your content.
          </p>
        }
        header="Authorization expire"
      />
      {schoolData && (
        <Form
          onSubmit={() => {
            setOnUpload(true);
            const data = new FormData();
            data.append(
              "SchoolLogo",
              state.NewSchoolLogo ? state.NewSchoolLogo : null
            );
            data.append("SchoolName", state.SchoolName);
            data.append("SchoolEmail", state.SchoolEmail);
            data.append("SchoolAddress", state.SchoolAddress);
            data.append("SchoolPhone", state.SchoolPhone);
            axios
              .put(`${url}/api/school/update-school-info${user.slug}`, data)
              .then((res) => {
                setDataSaved(true);
                setOnUpload(false);
              })
              .catch((err) => {
                console.log(err);
                setOnUpload(false);
              });
          }}
          className=""
        >
          <label htmlFor={"schoolLogo"} className="fw6">
            School Image (136 x 32)
          </label>
          <img
            htmlFor="schoolLogo"
            id="schoolLogo"
            src={
              schoolData.SchoolLogo
                ? schoolData.SchoolLogo + user.SASToken
                : logo
            }
            className="schoolLogo"
            title={schoolData.School || "School Logo"}
          />
          <Input
            type="file"
            id="schoolLogo"
            name="SchoolLogo"
            accept="image/png, image/svg, image/jpeg"
            onChange={async (event) => {
              if (contentEditable) {
                const selectedFile =
                  event.target.files[0] || state.NewSchoolLogo;
                const reader = new FileReader();

                const imgtag = document.getElementById("schoolLogo");
                imgtag.title = selectedFile?.name || "School Logo";

                reader.onload = function (event) {
                  imgtag.src = event.target.result;
                };

                reader.readAsDataURL(selectedFile);
                setState({
                  ...state,
                  NewSchoolLogo: await event.target.files[0],
                });
              }
            }}
            fluid
            disabled={!contentEditable}
          />
          <label htmlFor={"schoolName"} className="fw6">
            School Name
          </label>
          <Input
            type="text"
            id="schoolName"
            placeholder="Enter school name"
            name="SchoolName"
            value={state.SchoolName}
            onChange={(event) => {
              contentEditable &&
                setState({ ...state, SchoolName: event.target.value });
            }}
            fluid
          />
          <label htmlFor={"schoolEmail"} className="fw6">
            School Email
          </label>
          <Input
            type="text"
            id="schoolEmail"
            placeholder="Enter school email"
            name="SchoolEmail"
            value={schoolData.ContactEmail}
            fluid
          />
          <label htmlFor={"schoolPhone"} className="fw6">
            School Phone
          </label>
          <Input
            type="text"
            id="schoolPhone"
            placeholder="Enter school phone"
            name="SchoolPhone"
            value={state.SchoolPhone}
            onChange={(event) => {
              contentEditable &&
                setState({ ...state, SchoolPhone: event.target.value });
            }}
            fluid
          />
          <label htmlFor={"schoolAddress"} className="fw6">
            School Address
          </label>
          <TextArea
            type="text"
            id="schoolAddress"
            placeholder="Enter school address"
            name="SchoolAddress"
            value={state.SchoolAddress}
            onChange={(event) => {
              contentEditable &&
                setState({ ...state, SchoolAddress: event.target.value });
            }}
            fluid
          />

          <Flex gap="gap.smaller">
            {contentEditable ? (
              <Button
                content="Cancle"
                secondary
                className="mb-xl-4"
                onClick={(e) => {
                  e.preventDefault();
                  setContentEditable(false);
                }}
              />
            ) : (
              <Button
                content="Edit"
                primary
                className="btn-blue mb-xl-4"
                onClick={(e) => {
                  e.preventDefault();
                  setContentEditable(true);
                }}
              />
            )}
            {contentEditable && (
              <Button
                content="Save"
                primary
                loading={onUpload}
                className="btn-blue mb-xl-4"
              />
            )}
          </Flex>
        </Form>
      )}
    </div>
  );
};

export default School;
