import React, { useEffect, useState } from "react";
import { Slider } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import axios from "axios";
import config from "config";

import "./PriceSlider.css";

const PriceSlider = (props) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const div = document.querySelector(".ui-slider__input-wrapper");
      const newNode = document.querySelector("#span");
      div && newNode && div.parentNode.insertBefore(newNode, div.nextSibling);
    }, 50);
  }, []);
  return (
    <div className="PriceSlider user-select-none p-4">
      <section className="slider-section">
        <div className="contact-input-section h5 text-center">
          {t("PricingPage").teacherSelector[0] + " "}
          <input
            type="number"
            value={props.number}
            id="teacher-input"
            className="teacherInp"
            onChange={(e) => {
              const { value } = e.target;
              if (value >= props.max) {
                props.setNumber(props.max);
              } else if (value < 1) {
                props.setNumber(1);
              } else {
                props.setNumber(value);
              }
            }}
          />{" "}
          {t("PricingPage").teacherSelector[1]}
        </div>
        <div id="subscriber-list-size-slider" className="m-5 text-center">
          <Slider
            fluid
            min={props.min}
            max={props.max}
            value={props.number}
            onChange={(e) => props.setNumber(e.target.value)}
          />
          <span id="span" className="text-uppercase pl-4 d-inline-block">
            <a
              className="link cur-pointer"
              data-toggle="modal"
              data-target="#contact-pricing"
            >
              {t("PricingPage").more} 1200 »
            </a>
          </span>
        </div>
      </section>
      <div
        className="modal fade pr-0"
        id="contact-pricing"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contact-pricing"
        aria-hidden="true"
        style={{ backgroundColor: "#0000005e" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content text-left">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-column text-start pl-4 pb-3 pt-4 pr-0">
              <div
                style={{
                  overflow: "scroll",
                  maxHeight: "85vh",
                  paddingRight: "1rem",
                }}
              >
                <form
                  className="more-form"
                  onSubmit={(e) => {
                    e.preventDefault();

                    axios
                      .post(`${config.ENDPOINT}/api/user/get-contact`, {
                        mail: document.getElementById("pricing-input-email")
                          .value,
                        name: document.getElementById("pricing-input-name")
                          .value,
                        message: document.getElementById(
                          "pricing-input-message"
                        ).value,
                        school: document.getElementById(
                          "pricing-input-school-name"
                        ).value,
                        role: document.getElementById("pricing-input-role")
                          .value,
                        phone: document.getElementById(
                          "pricing-input-school-phone"
                        ).value,
                        teachers: document.getElementById(
                          "pricing-input-teachers"
                        ).value,
                      })
                      .then((res) => {
                        alert(
                          "Thank you for contacing us, we will get back to you very soon!"
                        );
                        document.getElementById("pricing-input-email").value =
                          "";
                        document.getElementById("pricing-input-name").value =
                          "";
                        document.getElementById("pricing-input-message").value =
                          "";
                        document.getElementById(
                          "pricing-input-school-name"
                        ).value = "";
                        document.getElementById("pricing-input-role").value =
                          "";
                        document.getElementById(
                          "pricing-input-school-phone"
                        ).value = "";
                        document.getElementById(
                          "pricing-input-teachers"
                        ).value = "";
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  method="POST"
                >
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-name"
                    >
                      {t("landing").footer[8][0]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="pricing-input-name"
                        placeholder={t("landing").footer[8][1]}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.User size="18" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-school-name"
                    >
                      {"School Name"}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="pricing-input-school-name"
                        placeholder={"Enter your school name"}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Home size="18" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-role"
                    >
                      {"Role"}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="pricing-input-role"
                        placeholder={"Head of the IT department"}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Award size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-email"
                    >
                      {t("landing").footer[8][2]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="email"
                        className="form-control form-control-prepend"
                        id="pricing-input-email"
                        placeholder={t("landing").footer[8][3]}
                        name="email"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Mail size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-school-phone"
                    >
                      {"School Phone"}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="pricing-input-school-phone"
                        placeholder={"Enter your school contact no"}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Phone size="18" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-teachers"
                    >
                      {"Number of teachers"}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="number"
                        min="5"
                        max="100000"
                        className="form-control form-control-prepend"
                        id="pricing-input-teachers"
                        placeholder={"Number of teachers"}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Users size="18" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="pricing-input-message"
                    >
                      {t("landing").footer[8][4]}
                    </label>
                    <div className="input-group input-group-merge">
                      <textarea
                        className="form-control"
                        id="pricing-input-message"
                        rows="3"
                        placeholder={t("landing").footer[8][5]}
                        name="message"
                        required
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check-terms"
                        onChange={() => setIsChecked(!isChecked)}
                        checked={isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-terms"
                      >
                        {t("landing").footer[8][6] + " "}
                        <Link to="/terms" style={{ color: "#d33139" }}>
                          {t("landing").footer[8][7]}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      id="submit-btn"
                      disabled={!isChecked}
                    >
                      {t("landing").footer[8][8]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;
