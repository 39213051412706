import StaticImage from "components/Blocks/Image";
import config from "config";
import React, { useEffect } from "react";
import "./ContentScroller.css";

const imageHandler = (elm) => {
  document
    .querySelectorAll(`.wrap${elm.substring(0, 1)} .block`)
    ?.forEach((itag) => {
      if (itag.classList.contains("active")) itag?.classList?.remove("active");
    });
  document
    .querySelector(`.wrap${elm.substring(0, 1)} .b${elm.substring(2, 3)}`)
    ?.classList?.add("active");
};
const scrollHandler = () => {
  const elems = document.querySelectorAll(".section-wrapper");
  const windowWidth = Math.round(window.innerHeight / 2) || 600;
  elems?.forEach((el) => {
    if (
      el.getBoundingClientRect()?.top > 0 &&
      el.getBoundingClientRect()?.top <= windowWidth
    ) {
      if (!el.classList.contains("active")) {
        el.classList.add("active");
        const elm = el.getAttribute("itemID");
        if (elm) {
          imageHandler(elm);
        }
      }
    } else {
      if (el.classList.contains("active")) el.classList.remove("active");
    }
  });
};

window.addEventListener("scroll", scrollHandler, true);

const ContentScroller = (props) => {
  useEffect(() => {
    document.querySelectorAll(".block.b1").forEach((node) => {
      node.classList.add("active");
    });
  }, []);
  return (
    <div className="scroller bg-white">
      <div className="container">
        <div className="row justify-content-center text-left text-md-center">
          <div className="10">
            <h2 className="display-4 space-x">
              {props?.data?.heading?.map((d, i) => {
                if (i % 2 === 0) {
                  return d;
                } else {
                  return (
                    <span className="text-brand" key={i}>
                      {" " + d + " "}
                    </span>
                  );
                }
              })}
            </h2>
          </div>
        </div>
        {props?.data?.content?.map((sec, key) => {
          return key % 2 === 0 ? (
            <div
              className="row scroller-container gx-2"
              key={`section-${key + 1}`}
            >
              <div className="text-start col-blocks col-md-6">
                {sec?.map((cnt, key2) => {
                  return (
                    <div className="section" key={`content-${key2 + 1}`}>
                      <div
                        className="section-wrapper"
                        itemID={key + 1 + "-" + (key2 + 1)}
                      >
                        <h3>{cnt.heading}</h3>
                        <h5 className="font-weight-light">{cnt.title}</h5>
                        <p className="text-muted font-italic">{cnt.detail}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={`col col-imgs wrap${key + 1} col-md-6`}>
                {sec &&
                  sec.map((cnt, key3) => {
                    return (
                      <div
                        className={`block b${
                          key3 + 1
                        } rounded-5 overflow-visible`}
                        key={`img-${key3 + 1}`}
                      >
                        <StaticImage
                          src={
                            `https://${config.STORAGE}.blob.core.windows.net/acv-products/` +
                            cnt?.img
                          }
                          alt="img"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div
              className="row scroller-container gx-2"
              key={`section-${key + 1}`}
            >
              <div className={`col col-imgs wrap${key + 1} col-md-6`}>
                {sec.map((cnt, key3) => {
                  return (
                    <div
                      className={`block b${
                        key3 + 1
                      } rounded-5 overflow-visible`}
                      key={`img-${key3 + 1}`}
                    >
                      <StaticImage
                        src={
                          `https://${config.STORAGE}.blob.core.windows.net/acv-products/` +
                          cnt?.img
                        }
                        alt="img"
                      />
                    </div>
                  );
                })}
              </div>
              <div className="text-start col-blocks col-md-6">
                {sec?.map((cnt, key2) => {
                  return (
                    <div className="section" key={`content-${key2 + 1}`}>
                      <div
                        className="section-wrapper"
                        itemID={key + 1 + "-" + (key2 + 1)}
                      >
                        <h3>{cnt.heading}</h3>
                        <h5 className="font-weight-light">{cnt.title}</h5>
                        <p className="text-muted font-italic">{cnt.detail}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {/* Mobile Version */}
        <div className="row scroller-container-mobile">
          {props?.data?.content?.map((sec, key) => {
            return sec.map((info, key2) => {
              return (
                <div className="col-12 col-md-6" key={key + "-" + key2}>
                  <div className="card">
                    <img
                      src={
                        `https://${config.STORAGE}.blob.core.windows.net/acv-products/` +
                        info?.img
                      }
                      className="card-img-top"
                      alt={info?.title}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{info?.title}</h5>
                      <p className="card-text">{info?.detail}</p>
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default ContentScroller;
