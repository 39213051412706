import { Alert } from "@fluentui/react-northstar";
import axios from "axios";
import config from "config";
import { UserContext } from "context/UserContext";
import React, { useContext, useEffect } from "react";
import * as Icon from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import affiliateIcon from "../../assets/img/affiliate-marketing.svg";

const ModalProfile = (props) => {
  const { user, nonSubscriber } = useContext(UserContext);
  const [referAmount, setReferAmount] = React.useState(0);
  const [referralCode, setReferralCode] = React.useState("");
  const [isCopy, setIsCopy] = React.useState(false);
  const { transaction, logout } = props;

  useEffect(() => {
    if (!user && nonSubscriber) return;
    getReferalCode();
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  }, [isCopy]);

  const getReferalCode = () => {
    const API = "/api/refer/getReferToken";
    axios
      .post(`${config.ENDPOINT}${API}`, {
        email: user.mail || "pd@devtim.tech",
      })
      .then((res) => {
        setReferralCode(res?.data?.referCode);
        setReferAmount(res?.data?.referBalance);
      });
  };

  const copyTextClipboard = () => {
    setIsCopy(true);
    const link = `${config.APP_URI}/prices/${referralCode}`;
    navigator.clipboard.writeText(link).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text:: ", err);
      }
    );
  };

  return (
    <div
      className="modal fade pr-0 fixed-right"
      id="modal-profile"
      tabIndex="-1"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-vertical" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="px-4">
              <div className="d-flex my-4">
                <div className="avatar-parent-child mx-auto">
                  {user?.image ? (
                    <img
                      alt="Image placeholder"
                      src={user.image + user.SASToken}
                      className="avatar avatar-xl rounded-circle"
                    />
                  ) : (
                    <div className="bg-light shadow-light text-white overflow-hidden avatar avatar-xl rounded-circle rounded-pill">
                      <Icon.User color="black" />
                    </div>
                  )}
                  <span className="avatar-child avatar-badge bg-success"></span>
                </div>
              </div>
              <div className="text-center mb-4">
                <h6 className="h5 mb-0">{user?.name}</h6>
                <span className="d-block text-muted">{user?.username}</span>
              </div>
              <div className="mt-2 pt-4 border-top"></div>
              <div>
                <div className="w-50 mx-auto">
                  <img
                    src={affiliateIcon}
                    alt="affiliate-icon"
                    className="w-100 h-auto"
                  />
                </div>
                <p className="text-center mt-2">
                  You have earned{" "}
                  <h4 className="font-weight-bold d-inline-block">
                    ${referAmount || 0}
                  </h4>
                </p>
              </div>
              <div>
                <div className="mt-2 pt-4 border-top"></div>
                <h5>Referral Link</h5>
                <div className="transactionContainer">
                  <p>Invite your friends to try out ACV!</p>
                  <p style={{ display: "flex" }}>
                    <b>{referralCode}</b>

                    <Icon.Copy
                      style={{ cursor: "pointer", margin: "0 15px" }}
                      onClick={copyTextClipboard}
                    />
                    {isCopy && (
                      <Alert
                        dismissAction="Index system"
                        header="Copied!"
                        success
                        visible
                        style={{ width: "fit-content", fontSize: "12px" }}
                      />
                    )}
                  </p>
                </div>
              </div>
              <div className="mt-2 pt-4 border-top"></div>
              {transaction.length > 0 && (
                <div>
                  <h5>My efftective referrals</h5>
                  <div className="transactionContainer">
                    {transaction.length > 0 &&
                      transaction.map((refer, key) => (
                        <div
                          className="d-flex flex-column border rounded-2 my-2 border-secondary p-2"
                          key={key}
                        >
                          <div className="d-flex justify-content-between gap-3">
                            <span className="text-truncate d-inline-block">
                              <span
                                style={{
                                  background: "#008aff",
                                  padding: "4px",
                                  borderRadius: "8px",
                                  color: "#fff",
                                }}
                              >
                                user:
                              </span>{" "}
                              {refer?.customerEmail}
                            </span>{" "}
                            {/* <span className="text-truncate d-inline-block">
                              {refer?.school}
                            </span> */}
                          </div>
                          <div className="d-grid mt-1">
                            <span className="text-truncate d-inline-block">
                              <span
                                style={{
                                  background: "#d3313a",
                                  padding: "4px",
                                  borderRadius: "8px",
                                  color: "#fff",
                                }}
                              >
                                Earned point:
                              </span>{" "}
                              {refer?.amount}
                            </span>
                            <span className="text-truncate d-inline-block mt-1">
                              <span
                                style={{
                                  background: "#ffbe3d",
                                  padding: "4px",
                                  borderRadius: "8px",
                                  color: "#fff",
                                }}
                              >
                                Plan:
                              </span>{" "}
                              {refer?.subscriptionPlan}
                            </span>
                            {/* <span className="text-truncate">
                              {refer?.teachers}
                            </span> */}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              <div className="mt-2 pt-4 border-top"></div>
              {user?.dbData?.Subscription !== "Gold" && (
                <div className="card shadow-none border-0 bg-section-secondary mt-4">
                  <div className="card-body pt-0 px-3 pb-0 text-center">
                    <div className="pb-4 px-5">
                      <img
                        src="https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/illustrations/illustration-15.svg"
                        className="img-fluid img-center"
                        alt="Illustration"
                      />
                    </div>
                    <h5 className="h5 mb-3">Upgrade to Pro</h5>
                    <p className="text-muted">
                      One year support, montlhy updates for up to 5 team
                      members.
                    </p>
                    <Link to="/prices" className="btn btn-sm btn-primary">
                      Upgrade
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer py-3 mt-auto">
            <a
              href="/"
              className="btn btn-block btn-sm btn-neutral btn-icon rounded-pill"
              onClick={() => logout()}
            >
              <span className="btn-inner--icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
              </span>{" "}
              <span className="btn-inner--text">Sign out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalContact = () => {
  const { t } = useTranslation();
  const [isError, setIsError] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(true);
  return (
    <div
      className="modal fade pr-0"
      id="contactusModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="contactUsModalTitle"
      aria-hidden="true"
      style={{ backgroundColor: "#0000005e" }}
    >
      <div className="modal-dialog modal-dialog-centered mt-0" role="document">
        <div className="modal-content">
          <div>
            <button
              type="button"
              className="close p-4"
              data-dismiss="modal"
              aria-label="Close"
              id="modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-column text-start mt-n5 p-0">
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    axios
                      .post(`${config.ENDPOINT}/api/user/get-contact`, {
                        mail: document.getElementById("input-email").value,
                        name: document.getElementById("input-name").value,
                        message: document.getElementById("input-message").value,
                      })
                      .then(() => {
                        document.querySelector("#modal-close-btn").click();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  method="POST"
                >
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="input-name">
                      {t("landing").footer[8][0]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="input-name"
                        placeholder={t("landing").footer[8][1]}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.User size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-control-label" htmlFor="input-email">
                      {t("landing").footer[8][2]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="email"
                        className="form-control form-control-prepend"
                        style={{ borderColor: isError ? "#d33139" : "" }}
                        id="input-email"
                        placeholder={t("landing").footer[8][3]}
                        name="email"
                        required
                      />
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            borderColor: isError ? "#d33139" : "",
                          }}
                        >
                          <Icon.Mail size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-message"
                    >
                      {t("landing").footer[8][4]}
                    </label>
                    <div className="input-group input-group-merge">
                      <textarea
                        className="form-control"
                        id="input-message"
                        rows="3"
                        placeholder={t("landing").footer[8][5]}
                        name="message"
                        required
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check-terms"
                        onChange={() => setIsChecked(!isChecked)}
                        checked={isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-terms"
                      >
                        {t("landing").footer[8][6] + " "}
                        <Link to="/terms" style={{ color: "#d33139" }}>
                          {t("landing").footer[8][7]}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      id="submit-btn"
                      disabled={!isChecked}
                    >
                      {t("landing").footer[8][8]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ModalContact, ModalProfile };
