import React from "react";
import * as Icon from "react-feather";
import CtaButton from "components/Blocks/CtaButton";

import "./MainHeading.css";
import { useTranslation } from "react-i18next";

const MainHeading = (props) => {
  const { t } = useTranslation();

  return (
    <div className="row justify-content-center text-left text-md-center">
      <div className="col-lg-12">
        <h1 className="display-3">
          {props?.data?.heading?.map((d, i) => {
            if (i % 2 === 0) {
              return d;
            } else {
              return (
                <span className="text-brand" key={i}>
                  {" " + d + " "}
                </span>
              );
            }
          })}
        </h1>
      </div>
      <div className="col-md-10 mt-4">
        <p className="text-muted">{props?.data?.info}</p>
      </div>
      <div className="col-md-5 mt-4">
        <CtaButton
          name={t("landing").cta[1]}
          link="prices"
          icon={<Icon.ArrowRight className="ms-2" />}
        />
      </div>
    </div>
  );
};

export default MainHeading;
