import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import IconLogo from "assets/img/icon.png";
import * as Icon from "react-feather";
import { Input, SearchIcon } from "@fluentui/react-northstar";
import config from "config";
import { useVerifyCouponHandlers } from "hooks";

const Footer = () => {
  const { t } = useTranslation();
  const [isError] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const {
    isLoading,
    verifyCoupon,
    coupon: urlCoupon,
  } = useVerifyCouponHandlers({});
  const toastMessage =
    urlCoupon && JSON.parse(localStorage.getItem("toastMessage"));

  const onChange = (value) => {
    onDisableButton(value);
  };

  useEffect(() => {
    if (document.querySelector(".modal-open")) {
      document.querySelector(".modal-backdrop.fade.show").remove();
      document.querySelector(".modal-open").classList.remove("modal-open");
    }
  }, []);

  window.onscroll = () => scrollFunction();
  const scrollFunction = () => {
    const topBtn = document.getElementById("topButton");
    if (topBtn) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        topBtn.style.display = "block";
        document.getElementById("navbar-main").style.borderBottom = "0px";
        document.getElementById("navbar-main").style.boxShadow =
          "0 4px 12px 0 rgb(0 0 0 / 7%),0 2px 4px rgb(0 0 0 / 5%)";
      } else {
        topBtn.style.display = "none";
        document.getElementById("navbar-main").style.boxShadow = "none";
        document.getElementById("navbar-main").style.borderBottom =
          "1px solid #f0f0f0";
      }
    }
  };
  const topHandler = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const onDisableButton = (data) => {
    if (!data) setBtnDisable(true);
    else if (coupon === "") setBtnDisable(true);
    else setBtnDisable(false);
  };

  const showMessage = () => {
    return (
      <div
        className={
          toastMessage.type === "Warning"
            ? "alert alert-warning fixed-bottom alert-dismissible fade show"
            : toastMessage.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{
          zIndex: "9999",
          // width: "500px",
          border: "2px solid white",
          position: "fixed",
          left: "auto",
          top: "100px",
          bottom: "auto",
          display: urlCoupon && isLoading && "flex",
          justifyContent: urlCoupon && isLoading && "center",
          alignItems: urlCoupon && isLoading && "center",
          gap: urlCoupon && isLoading && "10px",
        }}
      >
        {urlCoupon && isLoading && (
          <div
            className="spinner-border"
            role="status"
            style={{ width: "1rem", height: "1rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        {toastMessage.message}
        <button
          type="button"
          className="close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  return (
    <>
      {toastMessage ? showMessage() : null}
      <footer
        className={`position-relative`}
        // className={`position-relative ${props.classname}`}
        id="footer-main"
      >
        <div className="footer pt-lg-7 footer-dark bg-dark">
          <div className="shape-container shape-line shape-position-top shape-orientation-inverse">
            <svg
              width="2560px"
              height="100px"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              x="0px"
              y="0px"
              viewBox="0 0 2560 100"
              style={{ enableBackground: "new 0 0 2560 100" }}
              className="fill-section-primary"
            >
              <polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
          <div className="container pt-4">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <h3 className="text-secondary mb-2">
                      {t("landing").footer[0]}
                    </h3>
                    <p className="lead mb-0 text-white opacity-8">
                      {t("landing").footer[1]}
                    </p>
                  </div>
                  <div className="col-lg-5 text-lg-right mt-4 mt-lg-0">
                    <a
                      href={config.DOC_URL}
                      target="_blank"
                      className="btn btn-white btn-icon my-2 mx-1"
                    >
                      <span className="btn-inner--icon">
                        <Icon.Book className="text-primary" size="18" />{" "}
                      </span>
                      <span className="btn-inner--text">
                        {t("landing").hero[4]}
                      </span>{" "}
                    </a>
                    <Link to="/prices" className="btn btn-primary">
                      {t("landing").price[3]}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="btn btn-white btn-icon top-btn shadow-xl py-2 px-0"
              onClick={() => topHandler()}
              id="topButton"
              style={{ display: "none" }}
            >
              <span className="btn-inner--icon">
                <Icon.ArrowUp className="text-primary" size="18" />{" "}
              </span>
            </a>

            <div
              className="modal fade pr-0"
              id="contactusModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="contactusModal"
              aria-hidden="true"
              style={{ backgroundColor: "#0000005e" }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-dialog" role="document">
                <div
                  className="modal-content text-left"
                  style={{ top: "100px" }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-column text-start px-4 pb-3 pt-4">
                    <div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();

                          axios
                            .post(`${config.ENDPOINT}/api/user/get-contact`, {
                              mail: document.getElementById("input-email")
                                .value,
                              name: document.getElementById("input-name").value,
                              message:
                                document.getElementById("input-message").value,
                            })
                            .then(() => {
                              document
                                .querySelector("#modal-close-btn")
                                .click();
                              // props.settoastMessage({
                              //   message:
                              //     "Thank you for contacing us, we will get back to you very soon!",
                              //   type: "Success",
                              // });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        method="POST"
                      >
                        <div className="form-group">
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            {t("landing").footer[8][0]}
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              type="text"
                              className="form-control form-control-prepend"
                              id="input-name"
                              placeholder={t("landing").footer[8][1]}
                              name="name"
                              required
                            />
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <Icon.User size="18" />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            {t("landing").footer[8][2]}
                          </label>
                          <div className="input-group input-group-merge">
                            <input
                              type="email"
                              className="form-control form-control-prepend"
                              style={{ borderColor: isError ? "#d33139" : "" }}
                              id="input-email"
                              placeholder={t("landing").footer[8][3]}
                              name="email"
                              required
                            />
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{
                                  borderColor: isError ? "#d33139" : "",
                                }}
                              >
                                <Icon.Mail size="18" />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="form-control-label"
                            htmlFor="input-message"
                          >
                            {t("landing").footer[8][4]}
                          </label>
                          <div className="input-group input-group-merge">
                            <textarea
                              className="form-control"
                              id="input-message"
                              rows="3"
                              placeholder={t("landing").footer[8][5]}
                              name="message"
                              required
                            />
                          </div>
                        </div>
                        <div className="my-4">
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="check-terms"
                              onChange={() => setIsChecked(!isChecked)}
                              checked={isChecked}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="check-terms"
                            >
                              {t("landing").footer[8][6] + " "}
                              <Link to="/terms" style={{ color: "#d33139" }}>
                                {t("landing").footer[8][7]}
                              </Link>
                            </label>
                          </div>
                        </div>
                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-block btn-primary"
                            id="submit-btn"
                            disabled={!isChecked}
                          >
                            {t("landing").footer[8][8]}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="divider divider-fade divider-dark my-5" />
            <div className="row">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <Link to="/">
                  <img
                    alt="ACV - Tangible IT"
                    src={IconLogo}
                    className="navbar-logo"
                    id="footer-logo"
                  />
                </Link>
                <p className="mt-4 text-sm footer-link pr-lg-4">
                  {t("landing").footer[2]}
                </p>
              </div>

              <div className="col-lg-3 col-6 col-md-6 col-sm-4 mb-5 mb-lg-0">
                <h6 className="heading mb-3">{t("landing").footer[3][0]}</h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/terms">{t("landing").footer[3][1]}</Link>
                  </li>
                  <li>
                    <Link to="/privacy">{t("landing").footer[3][2]}</Link>
                  </li>
                  {/* <li>
                    <Link to="/privacy#cookies">
                      {t("landing").footer[3][3]}
                    </Link>
                  </li> */}
                  <li>
                    <a
                      className="cur-pointer"
                      data-toggle="modal"
                      data-target="#contactusModal"
                      id="btn-contact"
                    >
                      {t("landing").footer[3][4]}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 col-md-6 col-sm-4 mb-5 mb-lg-0">
                <h6 className="heading mb-3">{t("landing").footer[4][0]}</h6>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/profile">{t("landing").footer[3][5]}</Link>
                  </li>
                  <li>
                    <a
                      type="button"
                      data-toggle="modal"
                      data-target="#couponVerification"
                    >
                      {t("landing").footer[4][1]}
                    </a>
                    <div
                      className="modal fade pr-0"
                      id="couponVerification"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="couponModalLabel"
                      aria-hidden="true"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.37)" }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="couponModalLabel">
                              {t("landing").footer[4][1]}
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body coupon-modal-body">
                            <Input
                              icon={<SearchIcon />}
                              clearable
                              placeholder="Verify Coupon..."
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setCoupon(value);
                              }}
                              value={coupon}
                              className="verify_input"
                            />
                            {coupon && (
                              <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_CLIENT}
                                onChange={onChange}
                              />
                            )}
                            <button
                              type="button"
                              className="btn btn-primary py-2 mt-2"
                              disabled={btnDisable}
                              width="304px"
                              onClick={() => verifyCoupon(coupon)}
                            >
                              {t("landing").verify}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <Link to="/support" style={{ cursor: "pointer" }}>
                      {t("landing").footer[4][2]}
                    </Link>
                  </li>
                  <li>
                    <a href={config.DOC_URL} target="_blank">
                      {t("landing").footer[4][3]}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr className="divider divider-fade divider-dark my-4" />
            <div className="row align-items-center justify-content-md-between pb-4">
              <div className="col-md-12">
                <div
                  className="copyright text-sm font-weight-bold text-center"
                  style={{ color: "#6b6e8b" }}
                >
                  &copy; {t("landing").footer[5]}{" "}
                  <a href={config.APP_URI} className="font-weight-bold">
                    {t("landing").footer[6]}
                  </a>
                  {t("landing").footer[7]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
