import { createContext } from "react";

const userDefaultContext = {
  user: null,
  accessToken: null,
  nonSubscriber: false,
  insideTeam: false,
  setUser: () => {},
  setAccessToken: () => {},
  setNonSubscriber: () => {},
  isTrial:false
};

export const UserContext = createContext(userDefaultContext);
