import React from "react";
import MainHeading from "components/MainHeading";
import ImgToggler from "components/ImgToggler";
import ContentScroller from "components/ContentScroller";
import Cta from "components/Cta";
import Header from "components/Header";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
  const { t } = useTranslation();
  const data = t("Products")[1];
  return (
    <div className="btsp">
      <Header />
      <div className="space-x2">
        <div className="container">
          <MainHeading data={{ heading: data?.heading, info: data?.info }} />
          <ImgToggler />
        </div>
      </div>
      <ContentScroller data={data} />
      <Cta />
      <Footer classname="dashboard" />
    </div>
  );
};

export default Dashboard;
