import { useContext } from "react";

import { LeaveIcon } from "@fluentui/react-icons-northstar";
import { Button, Flex, Image, Segment } from "@fluentui/react-northstar";
import image from "assets/img/icon.png";
import { Link } from "react-router-dom";
import "./NonSubSciber.css";

import config from "config";
import { UserContext } from "context/UserContext";

const NonSubScriber = (props) => {
  const { logout } = props;
  const { user } = useContext(UserContext);

  return (
    <div className="Auth Karla">
      <Flex gap="gap.small" padding="padding.medium" className="flexBox">
        <Flex.Item hAlign="center" vAlign="center">
          <Segment
            content={
              <>
                <Link to="/">
                  <Image src={image} style={{ borderRadius: "4px" }} />
                </Link>
                <br />
                <br />
                <h1 style={{ color: "black" }}>Subscribe to ACV</h1>

                <p>
                  Sorry, we can't find an account with
                  {` ${user?.username ? user?.username : "this"} `}
                  email address. Please try again or create a new account.
                </p>

                <Button
                  icon={<LeaveIcon />}
                  content={`Sign out from ${user?.username}`}
                  iconPosition="before"
                  primary
                  onClick={() => logout()}
                />

                <Button
                  content={"How to Subscribe ACV"}
                  onClick={() => window.open(`${config.DOC_URL}/#acv-account`)}
                  className={"btn"}
                />
              </>
            }
            style={{ textAlign: "center" }}
          />
        </Flex.Item>
      </Flex>
    </div>
  );
};
export default NonSubScriber;
