import { useContext, useEffect, useRef, useState } from "react";
import emailIcon from "assets/svg/email.svg";

import axios from "axios";

import {
  Checkbox,
  Loader,
  TeamsMonochromeIcon,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import credit from "assets/img/credit.png";
import greenArrow from "assets/img/green-arrow.png";
import cancelIcon from "assets/svg/cancel.svg";
import tickIcon from "assets/svg/tick.svg";
import FAQ from "components/FAQ";
import Footer from "components/Footer";
import CommanHeader from "components/Header";
import PriceSlider from "components/PriceSlider";
import config from "config";
import { UserContext } from "context/UserContext";
import * as Icon from "react-feather";
import { GoogleLogin } from "react-google-login";
import Customer from "../Customer";
import { CustomerContext } from "../Customer/context/customer.context";
import { getCustomer } from "../PaymentData";
import "./Price.css";

const Price = ({
  insideTeam,
  login,
  renewPlan,
  openModel = false,
  loader,
  ...props
}) => {
  const { t } = useTranslation();

  const { user, accessToken } = useContext(UserContext);
  const { customer } = useContext(CustomerContext);

  const [isChecked, setIsChecked] = useState(true);
  const [priceData, setPriceData] = useState({});
  const [number, setNumber] = useState(5);
  const [isYearly, setIsYearly] = useState(false);
  const [pricingInfo, setPricingInfo] = useState([]);
  const [discount, setDiscount] = useState({});
  const [price, setPrice] = useState({});
  const [rangeLimit, setRangeLimit] = useState({});
  const [msgData, setMsgData] = useState(null);
  const [prices, setPrices] = useState([]);
  const [existingCustomer, setExistingCustomer] = useState(false);
  const { subscriptionId, setCustomer } = useContext(CustomerContext);
  const [open, setOpen] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isCreatingSessionArray, setIsCreatingSessionArray] = useState(
    Array(prices.length).fill(false)
  );
  const [socialType, setSocialType] = useState("");

  const language =
    window.localStorage.getItem("i18nextLng")?.substring(0, 2)?.toLowerCase() ||
    "en";

  const location = useLocation();
  const params = useParams();

  const pricingRefs = useRef([]);

  const subscriptionType = user?.["dbData"]?.["Subscription"] || "";

  const userData = {
    userName: user?.name,
  };

  useEffect(() => {
    if (
      customer &&
      customer.email &&
      document.getElementById("school-email") &&
      socialType === "microsoft"
    ) {
      document.getElementById("school-email").value = customer.email;
    }
  }, [customer, socialType]);

  const onFetchRegisterRoute = () => {
    if (location.pathname === "/register") {
      const customer = getCustomer();
      document.getElementById(`price-btn${customer.priceId}`).click();
      document.getElementById("school-name").value = customer.school;
      document.getElementById("user-name").value = customer.name;
      // document.getElementById("input-job").value = customer.job;
      document.getElementById("school-email").value = customer.email;
      document.getElementById("school-address").value = customer.address;
      document.getElementById("school-phone").value = customer.phone;
    }
  };

  useEffect(() => {
    setReferToken();
    if (!window.localStorage.getItem("el")) {
      window.localStorage.removeItem("registerModal");
      window.localStorage.removeItem("el");
      window.localStorage.removeItem("priceData");
    }
    setPrices([
      [
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][6],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][7],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][8],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][9],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][10],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[0][0],
          subfeature: t("priceMatrix")[0][11],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[10][0],
          subfeature: t("priceMatrix")[10][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[10][0],
          subfeature: t("priceMatrix")[10][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[10][0],
          subfeature: t("priceMatrix")[10][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[10][0],
          subfeature: t("priceMatrix")[10][4],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][1],
          bronze: "6",
          silver: "12",
          gold: "24",
        },
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[1][0],
          subfeature: t("priceMatrix")[1][6],
          bronze: "5",
          silver: "7",
          gold: "10",
        },
      ],
      [
        {
          feature: t("priceMatrix")[2][0],
          subfeature: t("priceMatrix")[2][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][1],
          bronze: "3",
          silver: "10",
          gold: "Unlimited",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][2],
          bronze: "5",
          silver: "25",
          gold: "50",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][4],
          bronze: "10",
          silver: "Unlimited",
          gold: "Unlimited",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][6],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][7],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][8],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[3][0],
          subfeature: t("priceMatrix")[3][9],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[11][0],
          subfeature: t("priceMatrix")[11][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[11][0],
          subfeature: t("priceMatrix")[11][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[11][0],
          subfeature: t("priceMatrix")[11][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[11][0],
          subfeature: t("priceMatrix")[11][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[11][0],
          subfeature: t("priceMatrix")[11][5],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][5],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[12][0],
          subfeature: t("priceMatrix")[12][6],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[13][0],
          subfeature: t("priceMatrix")[13][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[13][0],
          subfeature: t("priceMatrix")[13][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[13][0],
          subfeature: t("priceMatrix")[13][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[13][0],
          subfeature: t("priceMatrix")[13][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[14][0],
          subfeature: t("priceMatrix")[14][1],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[14][0],
          subfeature: t("priceMatrix")[14][2],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[14][0],
          subfeature: t("priceMatrix")[14][3],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[14][0],
          subfeature: t("priceMatrix")[14][4],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[14][0],
          subfeature: t("priceMatrix")[14][5],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][3],
          bronze: "8h",
          silver: "8h",
          gold: "4h",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][4],
          bronze: "",
          silver: "",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][6],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[4][0],
          subfeature: t("priceMatrix")[4][7],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
      ],
      [
        {
          feature: t("priceMatrix")[5][0],
          subfeature: t("priceMatrix")[5][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[5][0],
          subfeature: t("priceMatrix")[5][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[5][0],
          subfeature: t("priceMatrix")[5][3],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[5][0],
          subfeature: t("priceMatrix")[5][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[6][0],
          subfeature: t("priceMatrix")[6][1],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[6][0],
          subfeature: t("priceMatrix")[6][2],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[6][0],
          subfeature: t("priceMatrix")[6][3],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
      ],
      [
        {
          feature: t("priceMatrix")[7][0],
          subfeature: t("priceMatrix")[7][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[7][0],
          subfeature: t("priceMatrix")[7][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[7][0],
          subfeature: t("priceMatrix")[7][3],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
      ],
      [
        {
          feature: t("priceMatrix")[8][0],
          subfeature: t("priceMatrix")[8][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[8][0],
          subfeature: t("priceMatrix")[8][2],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[8][0],
          subfeature: t("priceMatrix")[8][3],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[8][0],
          subfeature: t("priceMatrix")[8][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[8][0],
          subfeature: t("priceMatrix")[8][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
      [
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][1],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][2],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][3],
          bronze: "Soon",
          silver: "Soon",
          gold: "Soon",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][4],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][5],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][6],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][7],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
        {
          feature: t("priceMatrix")[9][0],
          subfeature: t("priceMatrix")[9][8],
          bronze: "Yes",
          silver: "Yes",
          gold: "Yes",
        },
      ],
    ]);
  }, [language]);

  useEffect(() => {
    axios
      .get(`${config.ENDPOINT}/api/payment/get-price-config`)
      .then((res) => {
        setPrice(res.data.prices[0]);
        setRangeLimit(res.data.rangeLimit);
        setDiscount(res.data.discount);
        setPricingInfo(
          res.data.prices.sort((a, b) => a.acvPlan.CardId - b.acvPlan.CardId) ||
            []
        );
      })
      .then(() => {
        onFetchRegisterRoute();
      })
      .catch((err) => {
        console.log(err);
      });
    // const modalCloseEvent = modalCloseEventInit();
  }, []);
  useEffect(() => {
    const selectedTeacherCount = localStorage.getItem("selectedTeacherCount");
    if (selectedTeacherCount !== null) {
      setNumber(Number(selectedTeacherCount));
    }
  }, []);

  // useLayoutEffect(() => {
  //   // setTimeout(()=>{
  //   if (openModel && subscriptionId) {
  //     pricingRefs.current[subscriptionId]?.click();
  //   }
  //   // },3000)
  // }, [pricingInfo, openModel, pricingRefs, subscriptionId]);

  useEffect(() => {
    if (pricingInfo.length) {
      checkLoginProcess();
    }
  }, [pricingInfo]);

  useEffect(() => {
    const exist = Boolean(localStorage.getItem("IsUserCreateAccount"));
    setExistingCustomer(exist);
  }, [localStorage.getItem("IsUserCreateAccount")]);

  const setReferToken = () => {
    const token = params?.token || "";
    if (token) {
      localStorage.setItem("refertoken", token);
    } else {
      localStorage.removeItem("refertoken");
    }
  };

  const onPriceHandler = async (plan, price) => {
    const priceData = {
      price: price * 100,
      productId: plan.product.id,
      plan: plan.acvPlan.planName,
      priceId: plan.id,
      subscriptionID: plan,
    };
    setPriceData(priceData);
    window.localStorage.setItem("priceData", JSON.stringify(priceData));
  };

  const dynamicPrice = (plan) => {
    if (number >= rangeLimit.min1 && number <= rangeLimit.max1) {
      return Number(
        isYearly
          ? (number * plan.price1 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price1)
      ).toFixed(2);
    } else if (number >= rangeLimit.min2 && number <= rangeLimit.max2) {
      return Number(
        isYearly
          ? (number * plan.price2 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price2)
      ).toFixed(2);
    } else if (number >= rangeLimit.min3 && number <= rangeLimit.max3) {
      return Number(
        isYearly
          ? (number * plan.price3 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price3)
      ).toFixed(2);
    } else if (number >= rangeLimit.min4 && number <= rangeLimit.max4) {
      return Number(
        isYearly
          ? (number * plan.price4 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price4)
      ).toFixed(2);
    } else if (number >= rangeLimit.min5 && number <= rangeLimit.max5) {
      return Number(
        isYearly
          ? (number * plan.price5 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price5)
      ).toFixed(2);
    } else if (number >= rangeLimit.min6 && number <= rangeLimit.max6) {
      return Number(
        isYearly
          ? (number * plan.price6 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price6)
      ).toFixed(2);
    } else if (number >= rangeLimit.min7 && number <= rangeLimit.max7) {
      return Number(
        isYearly
          ? (number * plan.price7 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price7)
      ).toFixed(2);
    } else if (number >= rangeLimit.min8 && number <= rangeLimit.max8) {
      return Number(
        isYearly
          ? (number * plan.price8 * 12 * (100 - discount.year1)) / 100
          : Number(number * plan.price8)
      ).toFixed(2);
    } else if (number <= rangeLimit.min1) {
      return Number(
        isYearly
          ? (rangeLimit.min1 * plan.price1 * 12 * (100 - discount.year1)) / 100
          : Number(rangeLimit.min1 * plan.price1)
      ).toFixed(2);
    }
  };

  const dynamicDisc = (plan) => {
    if (number >= rangeLimit.min1 && number <= rangeLimit.max1) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price1 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price1 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min2 && number <= rangeLimit.max2) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price2 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price2 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min3 && number <= rangeLimit.max3) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price3 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price3 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min4 && number <= rangeLimit.max4) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price4 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price4 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min5 && number <= rangeLimit.max5) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price5 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price5 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min6 && number <= rangeLimit.max6) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price6 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price6 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min7 && number <= rangeLimit.max7) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price7 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price7 / plan.price1) * 100)
      );
    } else if (number >= rangeLimit.min8 && number <= rangeLimit.max8) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price8 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price8 / plan.price1) * 100)
      );
    } else if (number <= rangeLimit.min1) {
      return Math.round(
        isYearly
          ? Math.round(100 - (plan.price1 / plan.price1) * 100 + discount.year1)
          : Math.round(100 - (plan.price1 / plan.price1) * 100)
      );
    }
  };

  const addCouponHandler = (e) => {
    e.preventDefault();
    axios
      .post(`${config.ENDPOINT}/api/admin/post-coupon-user`, {
        Email: document.getElementById("school-email").value,
        SchoolName: document.getElementById("school-name").value,
        UserName: document.getElementById("user-name").value,
        SchoolAddress: document.getElementById("school-address").value,
        OfficePhone: document.getElementById("school-phone").value,
        Provider: socialType,
      })
      .then((res) => {
        setMsgData(
          {
            message:
              "We have saved your information successfully, you will get email invitation once we approved.",
            type: res.data.status,
          } || null
        );
        setTimeout(() => {
          setMsgData(null);
        }, 10000);
        // document.getElementById("close-modal").click();
        document.getElementById("school-email").value = "";
        document.getElementById("school-name").value = "";
        document.getElementById("user-name").value = "";
        document.getElementById("school-address").value = "";
        document.getElementById("school-phone").value = "";
      })
      .catch((err) => {
        console.log(err, err?.response);
        setMsgData({
          message: err.response?.data?.message,
          type: err.response.status,
        });
      });
  };

  const showMessage = () => {
    return (
      <div
        className={
          msgData.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{
          zIndex: "9999",
          width: "500px",
          position: "fixed",
          left: "auto",
          bottom: "0px",
        }}
      >
        {msgData.message}
        <button
          type="button"
          className="close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  const handleRegisterDialog = (priceId) => {
    window.localStorage.setItem("registerModal", priceId);
  };

  const checkLoginProcess = () => {
    const priceId = localStorage.getItem("registerModal");
    const isRequestForDemo = localStorage.getItem("RequestDemo");
    if (priceId) {
      const modalButton = document.getElementById(`price-btn${priceId}`);
      if (modalButton) {
        modalButton.click();
        window.localStorage.removeItem("registerModal");
        window.localStorage.removeItem("el");
        window.localStorage.removeItem("priceData");
      }
    }
    if (isRequestForDemo) {
      const modalButton = document.getElementById("request-demo");

      if (modalButton) {
        setIsEmail(true);
        setSocialType("microsoft");
        modalButton.click();
        if (document.getElementById("school-email")) {
          document.getElementById("school-email").value = customer?.email;
          window.localStorage.removeItem("RequestDemo");
        }
      }
    }
  };

  const upgradePlan = async (price_id, price, productId) => {
    setIsCreatingSessionArray((prevState) => {
      const newArray = [...prevState];
      newArray[price_id] = true;
      return newArray;
    });

    const postData = {
      priceID: price_id,
      email: user?.username ? user.username : user?.mail,
      price: price * 100,
      productId: productId,
      currency: "USD",
      paymentType: "subscription",
    };

    await axios
      .post(`${config.ENDPOINT}/api/payment/update-subscriptions`, postData)
      .then((res) => {
        setIsCreatingSessionArray([]);
        if (res.data.result.session)
          window.location.href = res.data.result.session.url;
      })
      .catch((err) => {
        setIsCreatingSessionArray([]);
        const { status } = err.response;
        if (status === 409) {
          setIsError(true);
        }
      });
  };

  const getPriceButton = (price) => {
    return (
      <button
        type="submit"
        className="btn btn-sm btn-danger hover-translate-y-n3 hover-shadow-lg mb-3 mx-auto d-flex justify-content-center align-items-center"
        id={`price-btn` + price.id}
        data-toggle="modal"
        data-target={
          (!user ||
            !user?.schoolInfo ||
            (user?.schoolInfo && user?.role !== "SuperAdmin")) &&
          "#registration-modal"
        }
        ref={(el) => {
          if (el) pricingRefs.current[price.id] = el;
        }}
        disabled={isCreatingSessionArray[price.id]}
        onClick={() => {
          localStorage.setItem("subscriptionId", price.id);
          if (
            Boolean(user && user?.schoolInfo && user?.role === "SuperAdmin")
          ) {
            upgradePlan(
              price.id,
              dynamicPrice(price.acvPlan),
              price.product.id
            );
          } else {
            handleRegisterDialog(price.id);
            onPriceHandler(
              price,
              isYearly
                ? dynamicPrice(price.acvPlan) * 12
                : dynamicPrice(price.acvPlan)
            );
            if (user) {
              setCustomer((prevState) => ({
                ...prevState,
                name: user.name,
                email: user.username,
              }));
            }
          }
        }}
      >
        {isCreatingSessionArray[price.id]
          ? "Loading..."
          : user?.schoolInfo && user?.role === "SuperAdmin"
          ? price.acvPlan.planName === subscriptionType
            ? "Upgrade now"
            : "Switch Plan"
          : t("landing").price[3]}
        {isCreatingSessionArray[price.id] && (
          <span
            className="spinner-border spinner-border-sm ms-2"
            role="status"
            aria-hidden="true"
          />
        )}
      </button>
    );
  };

  const onSuccess = (res) => {
    setIsEmail(true);
    document.getElementById("school-email").value = res.profileObj.email;
    setSocialType("google");
  };

  const onFailure = (err) => {
    console.log(err);
  };

  return (
    <div className={`Landing price ${!renewPlan && `mt-5`}`}>
      {msgData && showMessage()}
      {!renewPlan && <CommanHeader />}
      <div
        className="modal fade pr-0"
        id="contactusModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contactusModal"
        aria-hidden="true"
        style={{ backgroundColor: "#0000005e" }}
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          id="modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-dialog" role="document">
          <div className="modal-content text-left" style={{ top: "100px" }}>
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-column text-start px-4 pb-3 pt-4">
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    axios
                      .post(`${config.ENDPOINT}/api/user/get-contact`, {
                        mail: document.getElementById("input-email").value,
                        name: document.getElementById("input-name").value,
                        message: document.getElementById("input-message").value,
                      })
                      .then((res) => {
                        setMsgData(
                          {
                            message:
                              "Thank you for contacing us, we will get back to you very soon!",
                            type: res.data.status,
                          } || null
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  method="POST"
                >
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="input-name">
                      {t("landing").footer[8][0]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepend"
                        id="input-name"
                        placeholder={t("landing").footer[8][1]}
                        name="name"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.User size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-control-label" htmlFor="input-email">
                      {t("landing").footer[8][2]}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type="email"
                        className="form-control form-control-prepend"
                        id="input-email"
                        placeholder={t("landing").footer[8][3]}
                        name="email"
                        required
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.Mail size="18" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="input-message"
                    >
                      {t("landing").footer[8][4]}
                    </label>
                    <div className="input-group input-group-merge">
                      <textarea
                        className="form-control"
                        id="input-message"
                        rows="3"
                        placeholder={t("landing").footer[8][5]}
                        name="message"
                        required
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check-terms"
                        onChange={() => setIsChecked(!isChecked)}
                        checked={isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="check-terms"
                      >
                        {t("landing").footer[8][6] + " "}
                        <Link to="/terms" style={{ color: "#d33139" }}>
                          {t("landing").footer[8][7]}
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      id="submit-btn"
                      disabled={!isChecked}
                    >
                      {t("landing").footer[8][8]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade pr-0 ${open ? "show" : ""}`}
        id="registration-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registration-modal"
        aria-hidden="true"
        style={{ backgroundColor: "#0000005e" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content text-left">
            <Customer
              priceData={priceData}
              login={login}
              schoolInfo={user?.schoolInfo}
              userData={userData}
              noOfTeachers={number}
              accessToken={accessToken}
              setOpen={setOpen}
              setLoader={props.setLoader}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade pr-0"
        id="addModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCoupon"
        aria-hidden="true"
        style={{ backgroundColor: "#0000005e" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content text-left">
            <form onSubmit={(e) => addCouponHandler(e)} method="POST">
              <div className="modal-body">
                <div className="form-group">
                  <h5 className="modal-title pt-1 pb-4" id="addCoupon">
                    {t("landing").hero[3]}
                  </h5>
                  <label htmlFor="school-name">{t("landing").demo[0][0]}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="school-name"
                    placeholder={t("landing").demo[0][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="school-address">
                    {t("landing").demo[1][0]}
                  </label>
                  <textarea
                    className="form-control"
                    id="school-address"
                    placeholder={t("landing").demo[1][1]}
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="school-phone">
                    {t("landing").demo[2][0]}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="school-phone"
                    placeholder={t("landing").demo[2][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user-name">{t("landing").demo[3][0]}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="user-name"
                    placeholder={t("landing").demo[3][1]}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="school-email">
                    {t("landing").demo[4][0]}
                  </label>
                  {isEmail ? (
                    <div className="input-group input-group-merge d-flex">
                      <input
                        type="email"
                        className="form-control"
                        id="school-email"
                        placeholder={t("landing").demo[4][1]}
                        required
                        disabled={
                          socialType === "google" || socialType === "microsoft"
                        }
                      />
                      <Icon.X
                        color="#d33139"
                        fill="#d33139"
                        size="18"
                        style={{
                          right: "0",
                          top: "50%",
                          transform: "translate(0, -50%)",
                          cursor: "pointer",
                        }}
                        className="position-absolute mr-2 cursor-pointer"
                        onClick={() => {
                          setIsEmail(false);
                          document.getElementById("school-email").value = "";
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex gap-2">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            // borderColor: isError ? "#d33139" : "",
                            paddingRight: 0,
                          }}
                        >
                          <GoogleLogin
                            clientId={config.GOOGLE_CLIENT_ID}
                            buttonText=""
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            className="google_btn_1 "
                            scope="profile"
                            redirectUri={config.APP_URI}
                          />
                        </span>
                      </div>
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            // borderColor: isError ? "#d33139" : "",
                            color: "#4F57BF",
                          }}
                        >
                          <TeamsMonochromeIcon
                            size="large"
                            onClick={() => {
                              login("microsoft");
                              setSocialType("microsoft");
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </div>
                      <div
                        className="input-group-prepend"
                        onClick={() => {
                          setIsEmail(true);
                          setSocialType("custom");
                        }}
                      >
                        <span
                          className="input-group-text"
                          style={{
                            cursor: "pointer",
                            fontSize: "1rem",
                            color: "#596080",
                          }}
                        >
                          <img
                            src={emailIcon}
                            alt="email-icon"
                            className="mr-2"
                          />{" "}
                          Continue with Email
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-secondary py-2 mt-2 mb-3"
                  data-dismiss="modal"
                  onClick={() => {
                    localStorage.removeItem("RequestDemo");
                  }}
                >
                  {t("landing").demo[5][0]}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary py-2 mt-2 mb-3"
                >
                  {t("landing").demo[5][1]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <section
        className={`slice ${!renewPlan && "slice-lg"} bg-section-secondary`}
      >
        <div className="container">
          {/* {!renewPlan && ( */}
          <div className="row mb-5 justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2 className="mt-4">{t("landing").price[0]}</h2>
              <div className="mt-2">
                <p className="lead lh-180 text-muted">
                  {t("landing").price[1]}
                </p>
              </div>
            </div>
          </div>
          {/* )} */}
          <div className="row mb-5 justify-content-center text-center">
            <div className="usage-pricing-selctor__switch flex-row justify-content-center margin-bottom-xl">
              <div className="icon-container">
                <div className="billing-cycles-toggle">
                  <p
                    className="h5"
                    style={{ margin: "0 14px 0 0", color: "#04b17f" }}
                  >
                    Save {discount.year1}%
                  </p>
                  <img src={greenArrow} />
                  <div style={{ margin: "-10px" }}>
                    <div>
                      <button
                        className="btn btn-link h1 p-0 px-3"
                        disabled={isYearly}
                        onClick={() => setIsYearly(true)}
                      >
                        Monthly
                      </button>
                      <Checkbox
                        toggle
                        checked={isYearly}
                        onChange={(e, { checked }) => setIsYearly(checked)}
                        style={{ marginRight: "-14px", paddingBottom: "8px" }}
                      />
                      <button
                        className="btn btn-link h1 p-0 px-3"
                        disabled={!isYearly}
                        onClick={() => setIsYearly(false)}
                      >
                        Annually
                      </button>
                    </div>
                  </div>
                </div>
                {/* <Link to="/credits">
                  <div
                    className="billing-cycles-toggle"
                    style={{ cursor: "pointer" }}
                  >
                    <img className="creditImage" src={credit} />
                    <div style={{ margin: "-10px", marginTop: "-1px" }}>
                      <div>
                        <button
                          className="btn btn-link h1 p-0 px-3"
                          style={{ color: "#d33139" }}
                        >
                          AI Credit Store
                        </button>
                      </div>
                    </div>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
          <PriceSlider
            min={rangeLimit.min1}
            max={rangeLimit.max8}
            number={number}
            setNumber={(number) => {
              setNumber(number);
              localStorage.setItem("selectedTeacherCount", number);
            }}
          />
          <div className="main-div">
            <p className="student-label">
              {t("PricingPage").enroll}{" "}
              {price?.acvPlan?.Students
                ? number * price?.acvPlan?.Students
                : 25}{" "}
              {t("PricingPage").students}
            </p>
            <p
              className="pr-4 pb-2"
              style={{
                textAlign: "right",
                fontWeight: "600",
                color: "#121212",
              }}
            >
              {t("PricingPage").currencyNotice}
            </p>
          </div>

          <div className="row justify-content-center px-4">
            {pricingInfo?.length ? (
              pricingInfo?.map((price, key) => {
                return (
                  price?.acvPlan?.Estatus === "Active" && (
                    <div className="col-xl-4 col-md-6" key={"Plan-key" + key}>
                      <div
                        className="card card-pricing text-center px-3 shadow hover-scale-110"
                        style={{
                          backgroundColor:
                            price.acvPlan.planName === "Silver" && "#f5feff",
                        }}
                      >
                        {price.acvPlan.planName === "Silver" && (
                          <div className="wrap">
                            <span className="ribbon6">Popular</span>
                          </div>
                        )}
                        <div className="card-header py-5 border-0 delimiter-bottom">
                          <div className="h1 text-center mb-0 min-width-content">
                            $
                            <span className="price font-weight-bolder">
                              {dynamicPrice(price.acvPlan)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                            <span className="h5">
                              {isYearly ? "/year" : "/month"}
                            </span>
                          </div>
                          <span className="h6 text-muted">
                            {price.acvPlan.planName}
                          </span>
                          <br />
                          {dynamicDisc(price.acvPlan) !== 0 && (
                            <h6 className="rbn2">
                              <span>save {dynamicDisc(price.acvPlan)}%</span>
                            </h6>
                          )}
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled text-sm mb-4">
                            {key > 0 && (
                              <li>
                                All features of{" "}
                                {pricingInfo[key - 1].acvPlan.planName} Plan+
                              </li>
                            )}
                            {price.lookup_key === "Bronze_Plan" && (
                              <>
                                {price.acvPlan.attendance &&
                                  !pricingInfo[key - 1]?.acvPlan.attendance &&
                                  !pricingInfo[key - 2]?.acvPlan.attendance && (
                                    <li>Attendance</li>
                                  )}
                                {price.acvPlan.dashboard &&
                                  !pricingInfo[key - 1]?.acvPlan.dashboard &&
                                  !pricingInfo[key - 2]?.acvPlan.dashboard && (
                                    <li>Dashboard</li>
                                  )}
                                {price.acvPlan.whiteBoard &&
                                  !pricingInfo[key - 1]?.acvPlan.whiteboard &&
                                  !pricingInfo[key - 2]?.acvPlan.whiteboard && (
                                    <li>Whiteboard</li>
                                  )}
                                {price.acvPlan.assingment &&
                                  !pricingInfo[key - 1]?.acvPlan.assingment &&
                                  !pricingInfo[key - 2]?.acvPlan.assingment && (
                                    <li>Assignments</li>
                                  )}
                                {price.acvPlan.event &&
                                  !pricingInfo[key - 1]?.acvPlan.event &&
                                  !pricingInfo[key - 2]?.acvPlan.event && (
                                    <li>Events</li>
                                  )}
                                {price.acvPlan.feedback &&
                                  !pricingInfo[key - 1]?.acvPlan.feedback &&
                                  !pricingInfo[key - 2]?.acvPlan.feedback && (
                                    <li>Feedback</li>
                                  )}
                                {price.acvPlan.booking &&
                                  !pricingInfo[key - 1]?.acvPlan.booking &&
                                  !pricingInfo[key - 2]?.acvPlan.booking && (
                                    <li>Booking</li>
                                  )}
                                {/* {price.acvPlan.auditing &&
                                  !pricingInfo[key - 1]?.acvPlan.auditing &&
                                  !pricingInfo[key - 2]?.acvPlan.auditing && (
                                    <li>Auditing / Substitute</li>
                                  )} */}
                              </>
                            )}
                            {price.lookup_key === "Silver_Plan" && (
                              <>
                                <li>AI Features</li> <li>AI Credit 2X</li>
                              </>
                            )}
                            {price.lookup_key === "Gold_Plan" && (
                              <>
                                <li>AI Credit 5X</li>
                                <li>Video Avatars</li>{" "}
                              </>
                            )}
                          </ul>
                          {getPriceButton(price)}
                          {price.acvPlan.planName === "Bronze" &&
                            !renewPlan && (
                              <a
                                className="btn btn-sm btn-info hover-translate-y-n3 hover-shadow-lg mb-3 mx-1"
                                data-toggle="modal"
                                data-target="#addModel"
                                id="request-demo"
                                onClick={() => {
                                  localStorage.setItem("RequestDemo", "true");
                                  localStorage.setItem(
                                    "IsUserCreateAccount",
                                    true
                                  );
                                }}
                              >
                                <span className="btn-inner--text">
                                  {t("landing").hero[3]}
                                </span>
                              </a>
                            )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            ) : (
              <Loader
                size="medium"
                style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
              />
            )}
          </div>
          <div className="row justify-content-center">
            {pricingInfo?.length ? (
              <div className="table-container">
                <table className="table price-table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <div className="price-block h1 justify-content-center mb-0 min-width-content d-flex">
                          <div className="d-flex">
                            <span className="price-unit">$</span>
                            <span className="price">
                              {String(
                                dynamicPrice(pricingInfo[0]?.acvPlan || 0)
                              )}
                            </span>
                          </div>
                          <span
                            id="price-validity"
                            className="h5 align-self-end d-none d-sm-block"
                          >
                            {isYearly ? "/year" : "/month"}
                          </span>
                          <span
                            id="price-validity-sm"
                            className="h5 align-self-center align-self-md-end d-block s-sm-hidden"
                          >
                            {isYearly ? "/y" : "/m"}
                          </span>
                        </div>
                        <span className="h6 text-muted price-plan">
                          {pricingInfo[0]?.acvPlan.planName}
                        </span>
                      </th>
                      <th>
                        <div className="price-block h1 justify-content-center mb-0 min-width-content d-flex">
                          <div className="d-flex">
                            <span className="price-unit">$</span>
                            <span className="price">
                              {String(
                                dynamicPrice(pricingInfo[1]?.acvPlan || 0)
                              )}
                            </span>
                          </div>
                          <span
                            id="price-validity"
                            className="h5 align-self-end d-none d-sm-block"
                          >
                            {isYearly ? "/year" : "/month"}
                          </span>
                          <span
                            id="price-validity-sm"
                            className="h5 align-self-center align-self-md-end d-block s-sm-hidden"
                          >
                            {isYearly ? "/y" : "/m"}
                          </span>
                        </div>
                        <span className="h6 text-muted price-plan">
                          {pricingInfo[1]?.acvPlan.planName}
                        </span>
                      </th>
                      <th>
                        <div className="price-block h1 justify-content-center mb-0 min-width-content d-flex">
                          <div className="d-flex">
                            <span className="price-unit">$</span>
                            <span className="price">
                              {String(
                                dynamicPrice(pricingInfo[2]?.acvPlan || 0)
                              )}
                            </span>
                          </div>
                          <span
                            id="price-validity"
                            className="h5 align-self-end d-none d-sm-block"
                          >
                            {isYearly ? "/year" : "/month"}
                          </span>
                          <span
                            id="price-validity-sm"
                            className="h5 align-self-center align-self-md-end d-block s-sm-hidden"
                          >
                            {isYearly ? "/y" : "/m"}
                          </span>
                        </div>
                        <span className="h6 text-muted price-plan">
                          {pricingInfo[2]?.acvPlan.planName}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {prices?.length > 0 &&
                    prices?.map((table, index) => (
                      <tbody key={`prices.${index}`}>
                        <tr>
                          <td>
                            <span className="h5 feature badge-container">
                              {table[0]?.feature}{" "}
                              {(table[0]?.feature === t("priceMatrix")[13][0] ||
                                table[0]?.feature ===
                                  t("priceMatrix")[14][0]) && (
                                <span className="my-badge">NEW</span>
                              )}
                            </span>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {table?.length > 0 &&
                          table?.map((row, i) => (
                            <tr className="lead" key={`price.${i}`}>
                              <td className="sub-feature">{row?.subfeature}</td>
                              <td>
                                {row?.bronze === "Yes" ? (
                                  <span className="icon">
                                    <img src={tickIcon} alt="tick-icon" />
                                  </span>
                                ) : row?.bronze === "No" ? (
                                  <span className="icon">
                                    <img src={cancelIcon} alt="tick-icon" />
                                  </span>
                                ) : (
                                  row?.bronze
                                )}
                              </td>
                              <td>
                                {row?.silver === "Yes" ? (
                                  <span className="icon">
                                    <img src={tickIcon} alt="tick-icon" />
                                  </span>
                                ) : row?.silver === "No" ? (
                                  <span className="icon">
                                    <img src={cancelIcon} alt="tick-icon" />
                                  </span>
                                ) : (
                                  row?.silver
                                )}
                              </td>
                              <td>
                                {row?.gold === "Yes" ? (
                                  <span className="icon">
                                    <img src={tickIcon} alt="tick-icon" />
                                  </span>
                                ) : row?.gold === "No" ? (
                                  <span className="icon">
                                    <img src={cancelIcon} alt="tick-icon" />
                                  </span>
                                ) : (
                                  row?.gold
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ))}
                </table>
              </div>
            ) : (
              <Loader
                size="medium"
                style={{ paddingTop: "10vh", paddingBottom: "10vh" }}
              />
            )}
          </div>

          <div className="mt-2 text-center lead">
            <p className="mb-2 text-muted">
              {t("landing").price[4][0]}
              <br />
              {t("landing").price[4][1]}
              <a
                className="text-danger cur-pointer"
                data-toggle="modal"
                data-target="#contactusModal"
                id="btn-contact"
              >
                {t("landing").price[5]}
              </a>
              {t("landing").price[4][2]}
            </p>
          </div>
        </div>
      </section>
      <FAQ />
      {!renewPlan && <Footer classname="price" />}
    </div>
  );
};

export default Price;
