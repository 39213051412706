$(document).ready(function () {
  $(".black-section .indicators li").click(function () {
    var i = $(this).index();
    var targetElement = $(".black-section .tabs li");
    targetElement.eq(i).addClass("active");
    targetElement.not(targetElement[i]).removeClass("active");
  });
  $(".black-section .tabs li").click(function () {
    var targetElement = $(".black-section .tabs li");
    targetElement.addClass("active");
    targetElement.not($(this)).removeClass("active");
  });
});
$(document).ready(function () {
  $(".slider .swiper-pagination span").each(function (i) {
    $(this)
      .text(i + 1)
      .prepend("0");
  });
});

