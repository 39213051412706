import React from "react";
import {
  Flex,
  Image,
  Text,
  Card,
  cardFocusableBehavior,
  Grid,
  cardsContainerBehavior,
} from "@fluentui/react-northstar";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import config from "config";

import "./FeatureCardIn404.css";

const ClickableCard = ({ key_index, cardsData }) => {
  const { t } = useTranslation();

  const redirectToPage = (link) => {
    if (link === config.DOC_URL) window.open(link, "_blank");
    else window.open(link);
  };
  const lanIndex = key_index + 4;
  return (
    <Card
      accessibility={cardFocusableBehavior}
      aria-roledescription="user card"
      onClick={() => redirectToPage(cardsData.link)}
      className="feature_card m-2"
    >
      <Card.Header>
        <Text
          content={t("page_not_found")[lanIndex][0]}
          weight="bold"
          style={{ fontSize: "20px" }}
        />
      </Card.Header>
      <Card.Body>
        <Flex column gap="gap.small" className="image_wrapper">
          <Image src={cardsData?.image} />
        </Flex>
        <Text
          content={t("page_not_found")[lanIndex][1]}
          weight="semibold"
          style={{ fontSize: "15px" }}
        />
      </Card.Body>
    </Card>
  );
};

const FeatureCardIn404 = () => {
  const cardData = [
    {
      image: `https://${config.STORAGE}.blob.core.windows.net/react-images/feature (1).png`,
      Tilte: "Features",
      Text: "Consulte el comportamiento de la asistencia en cualquier momento y en cualquier lugar.",
      link: "/#Features",
    },
    {
      image: `https://${config.STORAGE}.blob.core.windows.net/react-images/dollar-coin.png`,
      Tilte: "Plans",
      Text: "suscríbase al plan ACV que sea el más adecuado para su escuela y comience a utilizar el ACV.",
      link: "/prices",
    },
    {
      image: `https://${config.STORAGE}.blob.core.windows.net/react-images/doc-file-format.png`,
      Tilte: "Docs",
      Text: "Explore más cosas usando los documentos. Puede encontrar formas de usar ACV de manera adecuada",
      link: config.DOC_URL,
    },
  ];
  const cardsNumber = 3;
  return (
    <Grid
      accessibility={cardsContainerBehavior}
      columns={3}
      className="feature_404_wrapper row"
    >
      {_.times(cardsNumber, (i) => {
        const nonZeroIndex = i;
        return (
          <ClickableCard
            className="col-4"
            key={nonZeroIndex}
            key_index={nonZeroIndex}
            cardsData={cardData[i]}
            index={nonZeroIndex}
            aria-label={`${nonZeroIndex} of ${cardsNumber}`}
          />
        );
      })}
    </Grid>
  );
};

export default FeatureCardIn404;
