import React from "react";
import CommanHeader from "components/Header";
import Footer from "components/Footer";
import construction from "assets/img/svg/construction.svg";
import { Link } from "react-router-dom";

const Support = ({ insideTeam }) => {
  return (
    <>
      <CommanHeader />
      <div className="text-center my-7">
        <img
          style={{ height: "60vh" }}
          src={construction}
          alt="construction image"
        />
        <h1 className="h2">Page is under construction!</h1>
        <Link to="/" className="btn btn-primary">
          Home
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default Support;
